import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdditionalInfo from "../Components/Product/AdditonInfo/AdditionalInfo";
import Product from "../Components/Product/ProductMain/Product";
import RelatedProducts from "../Components/Product/RelatedProducts/RelatedProducts";
import { fetchProductDetail } from "../Redux/productSlice";
import ProductDetailsSkeleton from "../Components/Product/ProductMain/SkeletonLoader";

const ProductDetails = () => {
  const { productId } = useParams();  

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.product.productDetail);

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductDetail(productId));
    }
  }, [dispatch, productId]);

  if (loading) {
    return <ProductDetailsSkeleton />;
  }

  return (
    <>
      <Product productData={data} loading={loading} />
      <AdditionalInfo productData={data} />
      <RelatedProducts data={data} />
    </>
  );
};

export default ProductDetails;
