import React, { useEffect, useState } from "react";
import "./MyAccountPage.css";
import { Grid, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../Redux/orderSlice";
import { logoutUser } from "../../Redux/authSlice";
import useSnackbar from "../../hooks/useSnackbar";
import SnackbarComponent from "../Snackbar";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../Services/authServices";

const MyAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { orders, loading } = useSelector((state) => state.order);
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const [activeMenu, setActiveMenu] = useState("profile");
  const [expandedOrder, setExpandedOrder] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (activeMenu === "order") {
      let params = {
        limit: 20,
        skip: 0,
        sort: "createdAt",
        sortType: "desc",
      };
      dispatch(getOrderList(params));
    }
  }, [dispatch, activeMenu]);

  const toggleOrderDetails = (orderNo) => {
    setExpandedOrder((prevOrder) => (prevOrder === orderNo ? null : orderNo));
  };

  const renderOrderDetails = (order) => (
    <tr>
      <td colSpan={5}>
        <table className="order-details-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>SKU</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((productItem) => {
              const product = productItem.productId[0];
              const variant = product.variants[0];
              return (
                <tr key={variant.id}>
                  <td>{product.title}</td>
                  <td>$ {productItem?.price || 0}</td>
                  <td>{productItem.quantity}</td>
                  <td>{variant.sku}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );

  const handleLogout = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/shop");
        showSnackbar("Logout successful!", "success");
      })
      .catch((error) => {
        showSnackbar(error.message || "Logout failed!", "error");
      });
  };

  const getFullName = () => {
    return user?.fName ? user?.fName + " " + user?.lName : "";
  };

  const validatePasswords = () => {
    const validationErrors = {};
    if (!password) validationErrors.password = "Password is required.";
    if (!confirmPassword)
      validationErrors.confirmPassword = "Confirm Password is required.";
    if (password && confirmPassword && password !== confirmPassword)
      validationErrors.match = "Passwords do not match.";
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleUpdatePassword = async () => {
    if (!validatePasswords()) return;

    try {
      const payload = {
        password: password,
      };
      const response = await updateProfile(payload);      
      if (response.success) {
        showSnackbar("Password updated successfully!", "success");
        setPassword("");
        setConfirmPassword("");
      } else {
        showSnackbar("Password updated failed!", "error");
      }
    } catch (error) {
      showSnackbar(
        error.response?.data?.message || "Failed to update password.",
        "error"
      );
    }
  };

  return (
    <section className="my-account container">
      <h2 className="page-title">My Account</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ul className="account-nav">
            <li>
              <a
                href="#profile"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu("profile");
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "profile" ? "menu-link_active" : ""
                }`}
              >
                Profile
              </a>
            </li>
            <li>
              <a
                href="#order"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu("order");
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "order" ? "menu-link_active" : ""
                }`}
              >
                Orders
              </a>
            </li>
            <li>
              <a
                href="#logout"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "logout" ? "menu-link_active" : ""
                }`}
              >
                Logout
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className="page-content my-account__dashboard">
            {activeMenu === "profile" && (
              <div className="loginSignUpTabsContentRegister myAccountPageForm">
                <form autoComplete="off">
                  <div>
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Name *"
                      value={getFullName()}
                      readOnly
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email address *"
                      value={user?.email}
                      readOnly
                    />
                  </div>
                </form>
                <br />
                <form autoComplete="off">
                  <div>
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <span className="error-message">{errors.password}</span>
                    )}
                  </div>
                  <div>
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {errors.confirmPassword && (
                      <span className="error-message">
                        {errors.confirmPassword}
                      </span>
                    )}
                    {errors.match && (
                      <span className="error-message">{errors.match}</span>
                    )}
                  </div>
                  <div>
                    <label>.</label>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleUpdatePassword}
                    >
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            )}

            {activeMenu === "order" && (
              <table className="orders-table">
                <thead>
                  <tr>
                    <th>Order No.</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton height={50} />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {orders &&
                      orders.map((order) => {
                        const orderId = order.address?.customOrderNo;
                        const orderNo = order._id.orderNo;
                        const date = new Date(
                          order.createdAt
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        });
                        const status = order.status;
                        const totalAmount = order.amount.toFixed(2);
                        const itemCount = order.products.length;
                        const isExpanded = expandedOrder === orderNo;

                        return (
                          <React.Fragment key={orderNo}>
                            <tr key={orderNo}>
                              <td>{orderId}</td>
                              <td>#{orderNo}</td>
                              <td>{date}</td>
                              <td>{status}</td>
                              <td>
                                ${totalAmount} for {itemCount}{" "}
                                {itemCount > 1 ? "items" : "item"}
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => toggleOrderDetails(orderNo)}
                                >
                                  VIEW
                                </button>
                              </td>
                            </tr>
                            {isExpanded && renderOrderDetails(order)}
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </Grid>
      </Grid>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </section>
  );
};

export default MyAccountPage;
