import React, { useState } from "react";
import "./AdditionalInfo.css";

const AdditionalInfo = ({ productData }) => {
  const { body_html } = productData;

  const [activeTab, setActiveTab] = useState("aiTab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="productAdditionalInfo">
      <div className="productAdditonalInfoContainer">
        <div className="productAdditionalInfoTabs">
          <div className="aiTabs">
            <p
              onClick={() => handleTabClick("aiTab1")}
              className={activeTab === "aiTab1" ? "aiActive" : ""}
            >
              Description
            </p>
          </div>
        </div>
        <div className="productAdditionalInfoContent">
          {activeTab === "aiTab1" && (
            <div className="productDescription">
              <p dangerouslySetInnerHTML={{ __html: body_html }}></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
