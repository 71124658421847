import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import "./ShoppingCart.css";

const CartSkeleton = () => {
  return (
    <Box p={3}>

      {/* Main Grid Layout for Products and Cart Totals */}
      <Grid container spacing={4}>
        {/* Product List Skeleton (Left Side) */}
        <Grid item xs={8}>
          {[...Array(3)].map((_, index) => (
            <Grid container spacing={2} alignItems="center" mb={2} key={index}>
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width="100%" height={150} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" />
              </Grid>
              <Grid item xs={3} container justifyContent="space-between">
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="rectangular" width={100} height={30} />
                <Skeleton variant="text" width="40%" />
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* Cart Totals Skeleton (Right Side) */}
        <Grid item xs={4}>
          <Box>
            <h3>Cart Totals</h3>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
            </Box>

            {/* Proceed Button Skeleton */}
            <Box mt={4}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartSkeleton;
