import { Box, Skeleton } from "@mui/material";
import React from "react";

function ProductSkeleton() {
  return (
    <React.Fragment>
      {Array.from(new Array(6)).map((_, index) => (
        <div className="sdProductContainer" key={index}>
          <Box sx={{ marginRight: 0.5, my: 5 }}>
            <Skeleton variant="rectangular" width={258} height={320} />

            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="20%" height={15} />
              <Skeleton width="80%" />
              <Skeleton width="20%" />
              <Skeleton width="50%" height={15} />
            </Box>
          </Box>
        </div>
      ))}
    </React.Fragment>
  );
}

export default ProductSkeleton;
