import React, { useState } from "react";
import "./Navbar.css";

import { useDispatch, useSelector } from "react-redux";

import logo from "../../Assets/chef-set-logo.avif";
import { Link, useNavigate } from "react-router-dom";

import { RiMenu2Line } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa6";
import { RiShoppingBagLine } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";

import Badge from "@mui/material/Badge";
import { Menu, MenuItem } from "@mui/material";
import { logoutUser } from "../../Redux/authSlice";
import SnackbarComponent from "../../Components/Snackbar";
import useSnackbar from "../../hooks/useSnackbar";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const cart = useSelector((state) => state.cart);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.style.overflow = mobileMenuOpen ? "auto" : "hidden";
  };

  const handleLogout = () => {
    handleClose()
    dispatch(logoutUser())
      .then(() => {
        navigate("/shop");
        showSnackbar("Logout successful!", "success");
      })
      .catch((error) => {
        showSnackbar(error.message || "Logout failed!", "error");
      });
  };

  const getFullName = () => {
    return user.fName ? user.fName + " " + user.lName : "User";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    navigate("/my-account");
    setAnchorEl(null);
    handleClose()
  };

  return (
    <>
      {/* Desktop Menu */}
      <nav className="navBar">
        <div className="logoLinkContainer">
          <div className="logoContainer">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="linkContainer">
            <ul>
              {/* <li>
                <Link to="/">HOME</Link>
              </li> */}
              <li>
                <Link to="/shop">PRODUCT</Link>
              </li>
              {/* <li>
                <Link to="/blog">BLOG</Link>
              </li> */}
              <li>
                <Link to="/clearance">CLEARANCE</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="iconContainer">
          <Link to="/cart">
            <Badge
              badgeContent={cart.items.length === 0 ? "0" : cart.items.length}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <RiShoppingBagLine size={22} />
            </Badge>
          </Link>

          {isAuthenticated ? (
            <span className="user-login" onClick={handleClick}>
              <span className="user-name">{getFullName() || "User"}</span>
              <FaRegUser size={22} />
            </span>
          ) : (
            <Link to="/auth">
              <FaRegUser size={22} />
            </Link>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleNavigate}>My account</MenuItem>
            {isAuthenticated && (
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            )}
          </Menu>
        </div>
      </nav>

      {/* Mobile Menu */}
      <nav>
        <div className="mobile-nav">
          {mobileMenuOpen ? (
            <MdOutlineClose size={22} onClick={toggleMobileMenu} />
          ) : (
            <RiMenu2Line size={22} onClick={toggleMobileMenu} />
          )}
          <div className="logoContainer">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <Link to="/cart">
            <Badge
              badgeContent={cart.items.length === 0 ? "0" : cart.items.length}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <RiShoppingBagLine size={22} color="black" />
            </Badge>
          </Link>
        </div>
        <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
          <div className="mobile-menuTop">
            <div className="mobile-menuList">
              <ul>
                {/* <li>
                  <Link to="/" onClick={toggleMobileMenu}>
                    HOME
                  </Link>
                </li> */}
                <li>
                  <Link to="/shop" onClick={toggleMobileMenu}>
                    PRODUCT
                  </Link>
                </li>
                {/* <li>
                  <Link to="/blog" onClick={toggleMobileMenu}>
                    BLOG
                  </Link>
                </li> */}
                <li>
                  <Link to="/clearance" onClick={toggleMobileMenu}>
                    CLEARANCE
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toggleMobileMenu}>
                    CONTACT
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="mobile-menuFooter">
            <div className="mobile-menuFooterLogin">
              {isAuthenticated ? (
                <span className="user-login">
                  <FaRegUser size={22} />
                  <span className="user-name">{getFullName() || "User"}</span>
                </span>
              ) : (
                <Link to="/auth">
                  <FaRegUser size={22} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </>
  );
};

export default Navbar;
