import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productDetailApi, productListApi, productTypeApi } from "../Services/productServices";

// Thunk to fetch the product list with query parameters
export const fetchProductList = createAsyncThunk(
  "product/fetchProductList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await productListApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to fetch the product type with query parameters
export const fetchProductType = createAsyncThunk(
  "product/fetchProductType",
  async (params, { rejectWithValue }) => {
    try {
      const response = await productTypeApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductDetail = createAsyncThunk(
  'product/fetchProductDetail',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await productDetailApi(productId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    loading: false,
    error: null,
    isNext: false,
    productType: {
      list: [],
      loading: false,
      error: null,
      isNext: false,
    },
    selectedProductType: null,
    productDetail: {
      data: {},
      loading: true
    },
  },
  reducers: {
    filterProductsByType: (state, action) => {
      state.selectedProductType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Product list
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.data;
        state.isNext = action.payload.isNext;
      })
      .addCase(fetchProductList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Product Types
    builder
      .addCase(fetchProductType.pending, (state) => {
        state.productType.loading = true;
        state.productType.error = null;
      })
      .addCase(fetchProductType.fulfilled, (state, action) => {
        state.productType.loading = false;
        state.productType.list = action.payload.data;
        state.productType.isNext = action.payload.isNext;
      })
      .addCase(fetchProductType.rejected, (state, action) => {
        state.productType.loading = false;
        state.productType.error = action.payload;
      });

      // Product Details
      builder
      .addCase(fetchProductDetail.pending, (state) => {
        state.productDetail.loading = true;
      })
      .addCase(fetchProductDetail.fulfilled, (state, action) => {
        state.productDetail.data = action.payload.data;
        state.productDetail.loading = false;
      })
      .addCase(fetchProductDetail.rejected, (state) => {
        state.productDetail.loading = false;
      });
  },
});

export const { filterProductsByType } = productSlice.actions;

export default productSlice.reducer;
