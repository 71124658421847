import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrderListApi } from "../Services/orderService";

export const getOrderList = createAsyncThunk(
  "order/orderList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getOrderListApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    loading: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    // Order List
    builder
      .addCase(getOrderList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload.data;
      })
      .addCase(getOrderList.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default orderSlice.reducer;
