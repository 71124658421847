import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isLoggedIn, login, register, logout } from "../Services/authServices";

// Thunk for login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await login(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for registration
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await register(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoggedInCheck = createAsyncThunk(
  "auth/checkIsLoggedIn",
  async (_, { rejectWithValue }) => {
    try {
      const data = await isLoggedIn();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      await logout(); // Call the logout API
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    logged: "",
    isAuthenticated: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle login
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // Handle registration
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // Handle IsLoggedIn
    builder.addCase(LoggedInCheck.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.logged = "wait";
    });
    builder.addCase(LoggedInCheck.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isAuthenticated = true;
        state.logged = "pass";
      } else {
        state.isAuthenticated = false;
        state.logged = "failed";
      }
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(LoggedInCheck.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
      state.logged = "failed";
    });

    // Handle logout
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.user = null;
      state.logged = "failed";
      state.isAuthenticated = false;
    });
  },
});

// export const {} = authSlice.actions;

export default authSlice.reducer;
