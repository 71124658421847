import axiosInstance from "../App/axiosInstance";

// add product to cart
export const addToCartProductApi = async (data) => {
  const response = await axiosInstance.post(`/cart`, data);
  return response.data;
};

// cart list
export const addToCartListApi = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await axiosInstance.get(`/cart?${queryString}`);
  return response.data;
};

// cart update
export const addToCartUpdateApi = async (data) => {
  const response = await axiosInstance.put(`/cart/${data._id}`, data);
  return response.data;
};

// cart remove product
export const addToCartRemoveApi = async (id) => {
  const response = await axiosInstance.delete(`/cart/${id}`);
  return response.data;
};

// place order
export const placeOrderApi = async (data) => {
  const response = await axiosInstance.post(`/order`, data);
  return response.data;
};
