import React from 'react';
import { Box, Skeleton, Grid, Typography } from '@mui/material';

const ProductDetailsSkeleton = () => {
  return (
    <Box sx={{ margin: 'auto', padding: '0 160px' }}>
      <Grid container spacing={3}>
        {/* Left column for image */}
        <Grid item xs={12} md={5}>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </Grid>
        
        {/* Right column for product details */}
        <Grid item xs={12} md={7}>
          {/* Skeleton for product title */}
          <Skeleton variant="text" width="80%" height={50} sx={{ marginBottom: '8px' }} />
          
          {/* Skeleton for price */}
          <Skeleton variant="text" width="15%" height={80} sx={{ marginBottom: '16px' }} />
          
          {/* Skeleton for SKU */}
          <Skeleton variant="text" width="30%" height={20} sx={{ marginBottom: '8px' }} />
          
          {/* Skeleton for Categories */}
          <Skeleton variant="text" width="40%" height={20} sx={{ marginBottom: '8px' }} />
          
          {/* Skeleton for Tags */}
          <Skeleton variant="text" width="40%" height={20} sx={{ marginBottom: '16px' }} />
          
          {/* Skeleton for quantity selector */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <Skeleton variant="rectangular" width={40} height={40} sx={{ marginRight: '16px' }} />
            <Skeleton variant="text" width={40} height={40} sx={{ marginRight: '16px' }} />
            <Skeleton variant="rectangular" width={40} height={40} />
          </Box>
          
          {/* Skeleton for Add to Cart button */}
          <Skeleton variant="rectangular" width="50%" height={50} sx={{ marginBottom: '16px' }} />
          
          {/* Skeleton for description */}
          <Typography variant="h6" sx={{ marginBottom: '8px' }}>
            <Skeleton variant="text" width="40%" />
          </Typography>
          <Skeleton variant="text" width="90%" sx={{ marginBottom: '8px' }} />
          <Skeleton variant="text" width="85%" sx={{ marginBottom: '8px' }} />
          <Skeleton variant="text" width="80%" sx={{ marginBottom: '8px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetailsSkeleton;
